<template>
  <div>
    <div style="margin: 10px 0">
          <el-input style="width: 200px" placeholder="请输入姓名" suffix-icon="el-icon-search" class="ml-5" v-model="xingm"></el-input>

<!--      <el-select style="width: 200px" placeholder="请选择" v-model="name">
              <el-option v-for="item in nameOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"></el-option>
      </el-select>-->

<!--  <el-select class="ml-5" v-model="email" placeholder="请选择">
                <el-option v-for="(item, index) in email"
                    :key="index"
                    :label="item"
                    :value="item"></el-option>
       </el-select>  -->


      <el-button class="ml-5" type="primary" icon="el-icon-search" @click="load">查询</el-button>
      <el-button type="danger" icon="el-icon-warning-outline" @click="reset">重置</el-button>
    </div>

    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd">新增数据 <i class="el-icon-circle-plus-outline"></i></el-button>
       <el-button type="danger" @click="delBatch">批量删除 <i class="el-icon-delete"></i></el-button>
      <el-upload action="https://lkwyznkj.cyou:9090/renydtfb/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">
        <el-button type="primary" class="ml-5">导入数据 <i class="el-icon-upload el-icon--right"></i></el-button>
      </el-upload>
      <el-button type="primary" @click="exp" class="ml-5">导出数据 <i class="el-icon-download"></i></el-button>
    </div>

    <el-table :data="tableData" border stripe :header-cell-class-name="'TableHeader'"  @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="ID" width="80" sortable></el-table-column>
      <el-table-column prop="xingm" label="姓名"></el-table-column>
      <el-table-column prop="gongpkh" label="工牌卡号"></el-table-column>
      <el-table-column prop="gongh" label="工号"></el-table-column>
      <el-table-column prop="gongz" label="工种"></el-table-column>
      <el-table-column prop="danw" label="单位"></el-table-column>
      <el-table-column prop="bum" label="部门"></el-table-column>
      <el-table-column prop="quy" label="区域"></el-table-column>
      <el-table-column prop="zuob" label="坐标"></el-table-column>

      <el-table-column label="操作"  width="280" align="center">
        <template slot-scope="scope">
          <el-button type="success" @click="handleEdit(scope.row)">编辑 <i class="el-icon-edit"></i></el-button>
          <el-button type="danger" @click="del(scope.row.id)">删除 <i class="el-icon-delete"></i></el-button>

        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 500px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[5, 10, 20, 30]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="30%" >
      <el-form label-width="80px" size="small">
        <el-form-item label="姓名">
          <el-input v-model="form.xingm" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="工牌卡号">
          <el-input v-model="form.gongpkh" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="工号">
          <el-input v-model="form.gongh" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="工种">
          <el-input v-model="form.gongz" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="单位">
          <el-input v-model="form.danw" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="部门">
          <el-input v-model="form.bum" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="区域">
          <el-input v-model="form.quy" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="坐标">
          <el-input v-model="form.zuob" autocomplete="off"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="el-icon-close" type="danger" @click="dialogFormVisible = false">取 消</el-button>
        <el-button class="el-icon-check" type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Renydtfb",
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      xingm: "",
      // email: "",
      form: {},
      dialogFormVisible: false,
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},

    }
  },
  created() {
    this.load()
  },
  methods: {
    reset() {
        this.xingm = ""
        // this.email = ""
        this.load()
      },
    load() {
      this.request.get("/renydtfb/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          xingm: this.xingm,
          // email: this.email,
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    save() {
      this.request.post("/renydtfb", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {}
      // this.$nextTick(() => {
       // if(this.$refs.img) {
        //   this.$refs.img.clearFiles();
       //  }
       //  if(this.$refs.file) {
       //   this.$refs.file.clearFiles();
       //  }
     // })
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
     //  this.$nextTick(() => {
      //   if(this.$refs.img) {
       //    this.$refs.img.clearFiles();
      //   }
      //   if(this.$refs.file) {
       //   this.$refs.file.clearFiles();
      //   }
     //  })
    },
    del(id) {
          // 首先弹出确认框
          this.$confirm('您确定删除这条数据吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            // 用户确认删除后，发起删除请求
            return this.request.delete("/renydtfb/" + id).then(res => {
              if (res.code === '200') {
                this.$message.success("删除成功");
                // 删除成功后刷新数据
                this.load();
              } else {
                // 删除失败，抛出错误以便被捕获
                throw new Error("Server returned non-200 code.");
              }
            }).catch(error => {
              console.error("删除请求失败:", error);
              this.$message.error("删除失败，请检查网络或联系管理员。");
            });
          }).catch(() => {
            // 用户取消删除，显示信息
            this.$message.warning("删除操作已取消");
          });
        },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    delBatch() {
           // 首先检查是否有多选的数据
          if (this.multipleSelection.length === 0) {
            this.$message.error("请至少选择一条数据！");
            // 如果没有选择任何数据，则直接返回，不执行后续操作
            return;
          }
          // 使用Promise链确保先弹出确认框，再根据用户选择执行删除操作
          this.$confirm('您确定批量删除选中的数据吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            // 用户确认删除后，提取选中项ID并发起删除请求
            let ids = this.multipleSelection.map(v => v.id);
            // 发起POST请求删除选中项
            return this.request.post("/renydtfb/del/batch", ids).then(res => {
                  if (res.code === '200') {
                    this.$message.success("批量删除成功");
                    // 删除成功后刷新数据
                    this.load();
                  } else {
                    // 删除失败，但这里应该捕获到的是成功的resolve，故应调整逻辑或错误处理
                    throw new Error("Server returned non-200 code.");
                  }
                })
                // 捕获请求错误或逻辑错误
                .catch(error => {
                  console.error("批量删除请求失败:", error);
                  this.$message.error("批量删除失败，请检查网络或联系管理员。");
                });
          }).catch(() => {
            // 用户取消删除，显示信息
            this.$message.warning("批量删除操作已取消");
          });
        },

    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
   // handleFileUploadSuccess(res) {
   //   this.form.file = res
  //  },
  //  handleImgUploadSuccess(res) {
  //    this.form.img = res
  //  },
    download(url) {
      window.open(url)
    },
    exp() {
      window.open("https://lkwyznkj.cyou:9090/renydtfb/export")
      this.$message.success("导出成功")
      this.load()
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    }
  }
}
</script>


<style>
.TableHeader {
  background: #eee!important;
}
</style>
